import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link } from 'react-router-dom';

const Books = () => {

  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchAllBooksApi = async () => {
      try {
        const bookdata = await axios.get("http://localhost:8000/books");
        console.log(bookdata)
        setBooks(bookdata.data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchAllBooksApi();
  }, []);

  const deleteBook = async (id) => {
    try {
      await axios.delete("http://localhost:8000/books/"+id);

      window.location.reload();
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>Books

      {books && books.map((elem) => {
        return (
          <div key={elem.id}>
            {elem.cover && <img src={elem.cover} alt={elem.title} />}
            <p>{elem.title}</p>
            <p>{elem.desc}</p>
            <p>{elem.price}</p>
            <button onClick={() => deleteBook(elem.id)}>Delete Book</button>
            <Link to={`/update/${elem.id}`}>Update Book</Link>
          </div>
        )
      })}

      <Link to={"/add"}>Add Books</Link>
    </div>
  )
}

export default Books