import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Add = () => {

  const navigate = useNavigate();

  const [addbooks, setbooksData] = useState({
    title: "",
    desc: "",
    price: "",
    cover: ""
  })

  const handleChange = (e) => {
    setbooksData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:8000/books", addbooks);
      navigate("/")
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <h1>add data for books</h1>
      <form >
        <label htmlFor="title">title</label>
        <input type='text' onChange={handleChange} name='title' />
        <label htmlFor="desc">desc</label>
        <input type='text' onChange={handleChange} name='desc' />
        <label htmlFor="price">price</label>
        <input type='number' onChange={handleChange} name='price' />
        <label htmlFor="cover">image</label>
        <input type='text' onChange={handleChange} name='cover' />
        <button onClick={handleSubmitAdd}>Add Books</button>
hello
      </form>

    </div>
  )
}

export default Add