import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const Update = () => {

  const navigate = useNavigate();

  const params = useParams()

  const [addbooks, setbooksData] = useState({
    title: "",
    desc: "",
    price: "",
    cover: ""
  })

  const handleChange = (e) => {
    setbooksData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    try {
      await axios.put("http://localhost:8000/books/" + params.id, addbooks);
      navigate("/")
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <h1>Update for books</h1>
      <form >
        <label htmlFor="title">title</label>
        <input type='text' onChange={handleChange} name='title' />
        <label htmlFor="desc">desc</label>
        <input type='text' onChange={handleChange} name='desc' />
        <label htmlFor="price">price</label>
        <input type='number' onChange={handleChange} name='price' />
        <label htmlFor="cover">image</label>
        <input type='text' onChange={handleChange} name='cover' />
        <button onClick={handleSubmitAdd}>update Books</button>
      </form>

    </div>
  )
}

export default Update